import React from "react";
import ReactDOM from "react-dom";
import App, { history } from "./App";
import Config from "./config";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
} from "@apollo/client";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import tokenProvider from "core/tokenProvider";
import { LOGIN_PATH } from "helpers/AuthenticatedRoute";
import AppContextProviders from "./AppContextProviders";

/* Sentry config */

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: Config.get("sentryUrlKey"),
  });
}

// The documentation explaining how to handle pagination cache can be found here:
// @see https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results

// The keyArgs are used to define a unique cache for each queries
// In the case of a paginated query with inifinite loading, we want to merge
// previous with next data. So we have to remove offset and limit keys
// and only handle filtering arguments
// @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#specifying-key-arguments
const DEFAULT_PAGINATED_KEYARGS = ["q", "filters", "sorted"];

// We're in the future, this is a fix for the Apollo issue which is fixed and merged in the next major release (3.3)
// @see https://github.com/apollographql/apollo-client/pull/7109
// If Apollo version has been upgraded to 3.3+, remove me!
const filterUsedKeyArgs = (wantedKeyArgs) => (queryArgs) =>
  wantedKeyArgs.filter((keyArg) =>
    Object.prototype.hasOwnProperty.call(queryArgs, keyArg)
  );

// It is a pain to maintain this list, but for now it will be enough.
// One day, we could add a mechanism to handle that with a little magic  :)
const paginatedQueryPolicies = [
  { name: "orders", keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS) },
  {
    name: "contractors",
    keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS),
  },
  { name: "offices", keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS) },
  {
    name: "postalCodes",
    keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS),
  },
  { name: "truckLines", keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS) },
  { name: "trucks", keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS) },
  { name: "users", keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS) },
  { name: "logs", keyArgs: filterUsedKeyArgs(DEFAULT_PAGINATED_KEYARGS) },
].reduce((acc, query) => {
  return {
    ...acc,
    [query.name]: {
      keyArgs: query.keyArgs,
      merge(existing, incoming) {
        if (!existing) {
          return incoming;
        }

        return Object.assign(
          {},
          {
            ...incoming,
            data: [...existing.data, ...incoming.data].filter(
              (current, index, array) =>
                array.findIndex((value) => value.__ref === current.__ref) ===
                index
            ),
          }
        );
      },
    },
  };
}, {});

/* Appolo client config */
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...paginatedQueryPolicies,
      },
    },
    // Those type policies are needed to reassure Apollo that we do want to
    // completely replace the orders field when updating the cache
    RoundLine: {
      fields: {
        items: {
          merge: false,
        },
      },
    },
    Round: {
      fields: {
        orders: {
          merge: false,
        },
        tasks: {
          merge: false,
        },
      },
    },
    Contractor: {
      fields: {
        locks: {
          merge: false,
        },
      },
    },
    OfficeTrucksPlanning: {
      fields: {
        contractorLockedList: {
          merge: false,
        },
      },
    },
    OrderPlanning: {
      fields: {
        contractorLockedList: {
          merge: false,
        },
      },
    },
  },
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "Truck":
        return `Truck:${responseObject.number}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});
const link = new HttpLink({
  uri: Config.get("middleware_url") + Config.get("apolloUri"),
});

const authLink = setContext((_, { headers }) => {
  const token = tokenProvider.getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const authHandler = onError(({ graphQLErrors, networkError }) => {
  const is401 =
    networkError &&
    networkError.response &&
    networkError.response.status === 401;

  if (
    (graphQLErrors &&
      graphQLErrors.find(
        (error) => error.extensions.code === "UNAUTHENTICATED"
      )) ||
    is401
  ) {
    tokenProvider.removeToken();
    history.push(LOGIN_PATH);
  }
});

export const client = new ApolloClient({
  cache,
  link: authLink.concat(authHandler).concat(link),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProviders>
      <App />
    </AppContextProviders>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
