import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useAppState from "helpers/useAppState";
import useAppDispatch from "helpers/useAppDispatch";
import Config from "config";

const Notification = ({ duration = Config.get("notifDefaultDuration") }) => {
  const notif = useAppState().notif;
  const notifMessage = notif.success || notif.error || null;
  const severity = notif.success ? "success" : "error";

  const dispatchNotif = useAppDispatch();

  const handleClose = () => {
    dispatchNotif({ type: "HIDE_NOTIF" });
  };

  const handleExit = () => {
    dispatchNotif({ type: "RESET_NOTIF" });
  };

  return (
    <Snackbar
      open={notif.show}
      autoHideDuration={100000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExit }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {notifMessage}
      </MuiAlert>
    </Snackbar>
  );
};

Notification.propTypes = {
  duration: PropTypes.number,
};

export default Notification;
